import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_RESET,
  GET_CUSTOMER_ORDERS_REQUEST,
  GET_CUSTOMER_ORDERS_SUCCESS,
  GET_CUSTOMER_ORDERS_FAIL,
  GET_CUSTOMER_ORDERS_RESET,
  ORDER_ID_REQUEST,
  ORDER_ID_SUCCESS,
  ORDER_ID_FAIL,
} from "../constants/order";

export const orderReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return { loading: true };
    case ORDER_CREATE_SUCCESS:
      return { loading: false, success: true, orderInfo: action.payload };
    case ORDER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const getCustomerOrdersReducer = (
  state = { customerOrders: [] },
  action
) => {
  switch (action.type) {
    case GET_CUSTOMER_ORDERS_REQUEST:
      return { loading: true };
    case GET_CUSTOMER_ORDERS_SUCCESS:
      return { loading: false, success: true, customerOrders: action.payload };
    case GET_CUSTOMER_ORDERS_FAIL:
      return { loading: false, error: action.payload };
    case GET_CUSTOMER_ORDERS_RESET:
      return { customerOrders: [] };
    default:
      return state;
  }
};

export const getOrderByIdReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case ORDER_ID_REQUEST:
      return { loading: true };
    case ORDER_ID_SUCCESS:
      return { loading: false, success: true, order: action.payload };
    case ORDER_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
