import React from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../utils/currency";
import { Info, AddShoppingCart } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import Alert from "./Alert";
import { addToCart } from "../actions/cart";

function ProductItem({ id, name, price, image }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const addItemToCart = () => {
    dispatch(addToCart(id, 1));
    setOpen(true);
  };

  return (
    <>
      <div className="px-4 mb-6">
        <div>
          <div className="flex mt-4 justify-center">
            <span>
              <img className="h-64 w-full  rounded-t-lg" src={image} alt="" />
            </span>
          </div>
          <div className="">
            <h4 className="text-center text-gray-600 mt-2 text-xl py-4">
              {name}
            </h4>
            <h4 className="text-center text-gray-600 font-bold">
              UGX {formatCurrency(price)}
            </h4>
          </div>

          <div className="mt-4 flex justify-center mb-4">
            <Link to={`/product/${id}`}>
              <span className="flex justify-between text-white border mr-4 w-36 rounded-lg text-sm px-4 py-2 bg-gray-500 font-semibold cursor-pointer">
                <span style={{ paddingTop: "2px" }}>Info</span> <Info />
              </span>
            </Link>

            <span
              style={{ backgroundColor: "#F59E0B" }}
              onClick={() => addItemToCart(id)}
              className="flex justify-between w-36 text-sm rounded-lg px-4 py-2 text-white font-semibold cursor-pointer"
            >
              <span style={{ paddingTop: "2px" }}>Add To Cart</span>
              <AddShoppingCart />
            </span>
          </div>
        </div>
      </div>

      <Alert
        open={open}
        setOpen={setOpen}
        message="Item added to cart"
        bgColor="success"
      />
    </>
  );
}

export default ProductItem;
