export default function Footer() {
  return (
    <div
      style={{ backgroundColor: "#0b1826" }}
      className="py-12 mt-24 text-white static bottom-0"
    >
      <div className="md:flex justify-center flex-wrap py-8 px-4">
        <div className="md:w-2/4 md:mr-8">
          <h4 className="font-bold text-2xl">CG Engineering Consults</h4>
          <p className="text-gray-300 py-4">
            CG Engineering Consults is a Ugandan engineering firm that deals in
            architectural and engineering work involving building, roads and
            infrastructure, project management and planning of towns and
            estates.
          </p>
        </div>

        <div className="md:w-1/4">
          <h4 className="font-bold text-2xl">Contact</h4>
          <ul className="text-gray-300">
            <li className="mt-4">Kayunga, Wakiso</li>
            <li className="mt-4">
              <a className="cursor-pointer" href="tel:+256704341970">
                +256 704 341 970
              </a>{" "}
              |{" "}
              <a className="cursor-pointer" href="tel:++256701083336">
                +256 772 473 255
              </a>
            </li>
            <li className="mt-4">
              <a href="mailto:info@cg.co.ug">info@cg.co.ug</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex justify-center px-4 text-white pt-12">
        <div>
          <p>
            &copy; 2022 CG Engineering Consults | Made by{" "}
            <a href="https://loftuganda.tech">Loft Uganda</a>
          </p>
        </div>
      </div>
    </div>
  );
}
