import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { getCustomerOrders, getOrderById } from "../actions/order";
import { formatCurrency } from "../utils/currency";
import { ShoppingBasketOutlined as OrderIcon } from "@mui/icons-material";
import moment from "moment";
import Loader from "./Loader";

function Orders() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { customerOrders, loading } = useSelector((state) => state.orders);

  const history = useHistory();

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }

    dispatch(getCustomerOrders(userInfo?.userId));
  }, [userInfo, history, dispatch]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <h4 className="text-center py-4 md:py-1 text-3xl mt-32 font-bold">
            Your Orders
          </h4>

          <div>
            {customerOrders && customerOrders.length < 1 && (
              <div>
                <div className="flex justify-center py-4">
                  <span className="text-6xl text-yellow-500">
                    <OrderIcon fontSize="inherit" />
                  </span>
                </div>
                <h2 className="text-center text-2xl text-gray-400">
                  No orders yet
                </h2>
              </div>
            )}
          </div>

          <div className="flex justify-center mt-4 px-4">
            <div>
              {customerOrders &&
                customerOrders.map((order) => (
                  <Link key={order._id} to={`/details/order/${order._id}`}>
                    <div
                      onClick={() => dispatch(getOrderById(order._id))}
                      className="flex  border-b py-8 cursor-pointer"
                    >
                      <div className="mr-4">
                        <h4 className="font-bold border-b bg-blue-500 text-white px-2">
                          <span className="hidden md:inline-block">Order</span>{" "}
                          ID
                        </h4>
                        <div className="text-sm">
                          #
                          {order._id.slice(
                            order._id.length - 3,
                            order._id.length
                          )}
                        </div>
                      </div>
                      <div className="mr-4">
                        <h4 className="font-bold">
                          <span className="hidden md:inline-block">No. </span>{" "}
                          Items
                        </h4>
                        <div className="text-sm">
                          {order.items.reduce(
                            (acc, item) => acc + Number(item.qty),
                            0
                          )}
                        </div>
                      </div>
                      <div className="mr-4">
                        <h4 className="font-bold">Total</h4>
                        <div className="text-sm text-yellow-500">
                          UGX {formatCurrency(order.totalPrice)}
                        </div>
                      </div>
                      <div>
                        <h4 className="font-bold">Date</h4>
                        <div className="text-sm">
                          {moment(order.createdAt).format("L, h:mm a")}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Orders;
