import {
  ShoppingCart as CartIcon,
  AccountCircle as UserIcon,
  Settings as SettingsIcon,
  ShoppingBasketOutlined as OrderIcon,
  ArrowDropDown as ArrowDown,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

import { Link } from "react-router-dom";

export function AccountButton({
  account,
  showAccount,
  handleSettings,
  capitalizaFirstLetter,
  userInfo,
  handleLogout,
  handleOrders,
  StyledBadge,
  cartCount,
}) {
  return (
    <div>
      <div>
        <span
          onClick={() => showAccount(!account)}
          onMouseLeave={() => showAccount(false)}
          className="mr-4 hidden cursor-pointer md:inline-block transition-all relative"
        >
          <span>
            <span className="mr-2">{<UserIcon />}</span>
            <span className="hidden md:inline-block text-sm pt-2 py-4">
              Hi, {capitalizaFirstLetter(userInfo.username.split(" ")[0])}!
            </span>
            <span className="hidden md:inline-block mr-2">
              <ArrowDown />
            </span>
          </span>

          {account && (
            <span
              className="absolute top-0 -left-1 mt-8 text-blue-500 bg-white
            shadow-lg px-6 py-4 rounded-lg"
            >
              <Link onClick={() => showAccount(false)} to={`/customer`}>
                <span className="flex hover:text-gray-500">
                  <span className="mr-2">
                    {<OrderIcon fontSize="medium" />}
                  </span>
                  <span className="text-sm mt-1">Orders</span>
                </span>
              </Link>

              <Link onClick={() => handleSettings()} to={`/user`}>
                <span className="flex mt-4 mb-4 hover:text-gray-500 z-10">
                  <span className="mr-2">
                    {<SettingsIcon fontSize="medium" />}
                  </span>
                  <span className="text-sm mt-1">Settings</span>
                </span>
              </Link>

              <span className="flex justify-center mt-6">
                <span
                  onClick={() => handleLogout()}
                  className="cursor-pointer bg-blue-500 text-white uppercase px-12 py-2 rounded-lg text-xs text-center"
                >
                  Logout
                </span>
              </span>
            </span>
          )}
        </span>

        <span className="mr-4 md:hidden cursor-pointer inline-block transition-all relative">
          <span onClick={() => showAccount(!account)}>
            <span className="mr-2">{<UserIcon />}</span>
            <span className="hidden md:inline-block text-sm pt-2 py-4">
              Hi, {capitalizaFirstLetter(userInfo.username)}!
            </span>
            <span className="hidden md:inline-block mr-2">
              <ArrowDown />
            </span>
          </span>

          {account && (
            <span
              className="absolute top-0 -right-12 mt-8 text-blue-500 bg-white
          shadow-lg px-8 py-4 rounded-lg"
            >
              <span className="flex hover:text-gray-500">
                <span className="mr-2">{<OrderIcon fontSize="medium" />}</span>
                <span className="text-sm mt-1">
                  <Link onClick={() => handleOrders()} to="/customer">
                    Orders
                  </Link>
                </span>
              </span>
              <span className="flex mt-4 mb-4 hover:text-gray-500 z-10">
                <span className="mr-2">
                  {<SettingsIcon fontSize="medium" />}
                </span>
                <span className="text-sm mt-1">
                  <Link onClick={() => showAccount(false)} to={`/user`}>
                    Settings
                  </Link>
                </span>
              </span>

              <span className="flex justify-center mt-6">
                <span
                  onClick={() => handleLogout()}
                  className="cursor-pointer bg-blue-500 text-white uppercase px-6 py-2 rounded-lg text-xs text-center"
                >
                  Logout
                </span>
              </span>
            </span>
          )}
        </span>

        <span className="cursor-pointer">
          <Link onClick={() => showAccount(false)} to="/cart">
            <span className="mr-1 mb-4">
              <IconButton>
                <StyledBadge badgeContent={cartCount} color="info">
                  <CartIcon style={{ color: "#fff" }} />
                </StyledBadge>
              </IconButton>
            </span>
            <span className="hidden md:inline-block">Cart</span>
          </Link>
        </span>
      </div>
    </div>
  );
}

export function AccountButtonAlt({
  searchQuery,
  setSearchQuery,
  handleSearch,
  showAccount,
  StyledBadge,
  cartCount,
}) {
  return (
    <div>
      <span className="mr-6 cursor-pointer bg-white text-black px-4 py-2  rounded-lg text-sm uppercase">
        <Link to="/login">Login</Link>
      </span>

      <span className="cursor-pointer">
        <Link onClick={() => showAccount(false)} to="/cart">
          <span className="mb-4">
            <IconButton>
              <StyledBadge badgeContent={cartCount} color="info">
                <CartIcon style={{ color: "#fff" }} />
              </StyledBadge>
            </IconButton>
          </span>
          <span className="ml-2 hidden md:inline-block">Cart</span>
        </Link>
      </span>
    </div>
  );
}
