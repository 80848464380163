import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Header from "./components/Navigation";
import Footer from "./components/Footer";

import Home from "./views/HomePage";
import Login from "./views/LoginPage";
import Signup from "./views/SignupPage";
import User from "./views/UserPage";
import Product from "./views/ProductPage";
import Cart from "./views/CartPage";
import Shipping from "./views/ShippingPage";
import Payment from "./views/PaymentPage";
import Order from "./views/OrderPage";
import MyOrders from "./views/MyOrdersPage";
import Reset from "./views/ResetPage";
import OrderById from "./views/OrderByIdPage";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <div style={{ minHeight: "100vh" }}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/user" component={User} />
            <Route path="/product/:id" component={Product} />
            <Route path="/cart/:id?" component={Cart} />
            <Route path="/shipping" component={Shipping} />
            <Route path="/pay" component={Payment} />
            <Route path="/order" component={Order} />
            <Route path="/details/order/:id" component={OrderById} />
            <Route path="/customer" component={MyOrders} />
            <Route path="/reset/:token?" component={Reset} />
          </Switch>
        </div>
        <Footer />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
