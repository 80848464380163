import {
  GET_CUSTOMER_ORDERS_REQUEST,
  GET_CUSTOMER_ORDERS_SUCCESS,
  GET_CUSTOMER_ORDERS_FAIL,
  ORDER_CREATE_RESET,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_ID_REQUEST,
  ORDER_ID_SUCCESS,
  ORDER_ID_FAIL,
} from "../constants/order";
import axios from "axios";
import { CART_CLEAR } from "../constants/cart";
import { logout } from "./user";

export const createOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CREATE_RESET,
    });

    dispatch({
      type: ORDER_CREATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `https://cgug.herokuapp.com/orders/create`,
      order,
      config
    );

    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: CART_CLEAR,
      payload: data,
    });

    localStorage.removeItem("cartItems");
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    if (
      message === "Not authorized, no token" ||
      "Not authorized, token failed"
    ) {
      dispatch(logout());
    }

    dispatch({
      type: ORDER_CREATE_FAIL,
      payload: message,
    });
  }
};

export const getCustomerOrders = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_CUSTOMER_ORDERS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `https://cgug.herokuapp.com/orders/customer/${id}`,
      config
    );

    dispatch({
      type: GET_CUSTOMER_ORDERS_SUCCESS,
      payload: data.orders,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    if (
      message === "Not authorized, no token" ||
      "Not authorized, token failed"
    ) {
      dispatch(logout());
    }
    dispatch({ type: GET_CUSTOMER_ORDERS_FAIL, payload: message });
  }
};

export const getOrderById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_ID_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `https://cgug.herokuapp.com/orders/${id}`,
      config
    );

    dispatch({
      type: ORDER_ID_SUCCESS,
      payload: data.order,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    if (
      message === "Not authorized, no token" ||
      "Not authorized, token failed"
    ) {
      dispatch(logout());
    }
    dispatch({ type: ORDER_ID_FAIL, payload: message });
  }
};
