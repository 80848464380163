import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { updatePassword } from "../actions/user";
import { Link } from "react-router-dom";
import Alert from "./Alert";
import { CheckCircle as SuccessIcon } from "@mui/icons-material";
import Loader from "./Loader";

function Reset() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const history = useHistory();
  const { token } = useParams();

  // const redirect = location.search ? location.search.split("=")[1] : "/";

  const [open, setOpen] = useState(false);
  const [passCheck, setPassCheck] = useState(false);

  const [whenError, setWhenError] = useState(false);

  const passwordState = useSelector((state) => state.password);
  const { loading, success, error } = passwordState;
  const dispatch = useDispatch();

  const handleForgot = () => {
    if (!password || !confirmPassword) {
      setOpen(true);
    } else if (password && password !== confirmPassword) {
      setPassCheck(true);
    } else {
      dispatch(updatePassword(token, password));
      setPassword("");
      setConfirmPassword("");
    }
  };

  useEffect(() => {
    if (!token) {
      history.push("/login");
    }

    if (error) {
      setWhenError(true);
    }
  }, [token, history, error]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {success && (
            <div>
              <div className="text-center text-xl py-4 mt-32 font-bold">
                <span className="mr-2 text-green-600">
                  <SuccessIcon fontSize="medium" />
                </span>
                <span className="">Successful</span>
              </div>

              <div className="">
                <p className="text-center text-gray-600">
                  Password has been updated. Please login{" "}
                  <span className="text-blue-400">
                    <Link to="/login">here</Link>
                  </span>
                </p>
              </div>
            </div>
          )}

          {!success && (
            <>
              <h2 className="text-center mt-32 uppercase font-bold text-3xl mb-12">
                Update Password
              </h2>

              <div className="flex justify-center">
                <div>
                  <div className="mt-4">
                    <input
                      type="password"
                      value={password}
                      placeholder="password"
                      autoComplete="off"
                      className=" text-center py-1 px-12 rounded-lg border-2 focus:outline-none focus:border-blue-400"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="mt-8">
                    <input
                      type="password"
                      value={confirmPassword}
                      placeholder="confirm password"
                      autoComplete="password"
                      className=" text-center py-1 px-12 rounded-lg border-2 focus:outline-none focus:border-blue-400"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-8">
                <span
                  onClick={() => handleForgot()}
                  className="bg-blue-500 text-white px-28 py-2 rounded-lg cursor-pointer"
                >
                  Reset
                </span>
              </div>
            </>
          )}
        </div>
      )}

      <Alert
        open={open}
        setOpen={setOpen}
        message="All fields are required"
        bgColor="error"
      />

      <Alert
        open={passCheck}
        setOpen={setPassCheck}
        message="Passwords do not match"
        bgColor="error"
      />

      {error && (
        <Alert
          open={whenError}
          setOpen={setWhenError}
          message={error}
          bgColor="error"
        />
      )}
    </>
  );
}

export default Reset;
