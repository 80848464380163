import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getOrderById } from "../actions/order";
import { formatCurrency } from "../utils/currency";
import { ArrowBackIos as BackIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Loader from "./Loader";

function OrderId() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { order, loading } = useSelector((state) => state.orderById);

  const { id: orderId } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }

    dispatch(getOrderById(orderId));
  }, [history, userInfo, dispatch, orderId]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="pt-32 px-4 md:px-12">
            <Link to="/customer">
              <BackIcon fontSize="large" />
            </Link>
          </div>

          {order && (
            <div>
              <h4 className="text-center text-lg mt-4 font-semibold">
                Order ID: <span className="font-normal">#{order._id}</span>
              </h4>

              <div>
                <div className="text-center text-sm py-2">
                  {order.user.username}
                </div>
                <div className="text-center text-sm">
                  {order.shippingAddress.address}, {order.shippingAddress.city}
                </div>

                <div className="text-center text-sm py-2">
                  {order.shippingAddress.phone}
                </div>
              </div>

              <div className="mt-8">
                <h4 className="text-center font-semibold mb-4">
                  Payment Method
                </h4>

                <div className="text-center text-sm">{order.paymentMethod}</div>
              </div>

              <div className="mt-8">
                <h4 className="text-center font-semibold mb-4">
                  Products Summary
                </h4>

                <div className="flex justify-center mb-3">
                  <div>
                    <span className="mr-2">Total Items:</span>
                    {order.items.length}
                  </div>
                </div>

                {/* <div className="flex justify-center py-4">
                  <div className="flex justify-center mb-3 mr-4">
                    <div>
                      <span className="mr-2">Paid:</span>
                      {order.payment.isPaid ? (
                        <span className="px-2 py-1 bg-yellow-500 text-white uppercase">
                          Yes
                        </span>
                      ) : (
                        <span className="px-2 py-1 bg-blue-500 text-white uppercase">
                          No
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="flex justify-center mb-3">
                    <div>
                      <span className="mr-2">Delivered:</span>
                      <span className="px-2 py-1 bg-blue-500 text-white uppercase">
                        {order.delivery.isDelivered ? "Yes" : "No"}
                      </span>
                    </div>
                  </div>
                      </div> */}

                <div className="flex justify-center">
                  <div>
                    <span className="font-bold">Total Price: </span>
                    <span className="text-yellow-500">
                      UGX {formatCurrency(order.totalPrice)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default OrderId;
