import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../actions/product";
import ProductItem from "./ProductItem";
import Loader from "./Loader";

function Products() {
  const dispatch = useDispatch();
  const { productsList, loading } = useSelector((state) => state.getProducts);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="md:flex md:flex-wrap justify-center mt-36">
          {productsList &&
            productsList.map((item) => (
              <div className="w-full md:w-1/2" key={item._id}>
                <ProductItem
                  id={item._id}
                  name={item.name}
                  price={item.price}
                  image={item.image}
                />
              </div>
            ))}
        </div>
      )}
    </>
  );
}

export default Products;
