import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_QUERY_REQUEST,
  PRODUCT_QUERY_SUCCESS,
  PRODUCT_QUERY_FAIL,
  PRODUCT_ID_REQUEST,
  PRODUCT_ID_SUCCESS,
  PRODUCT_ID_FAIL,
} from "../constants/product";

export const getProductsReducer = (state = { productsList: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_LIST_SUCCESS:
      return { loading: false, succces: true, productsList: action.payload };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getProductByIdReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_ID_REQUEST:
      return { loading: true };
    case PRODUCT_ID_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PRODUCT_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getProductSearchReducer = (
  state = { productsList: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_QUERY_REQUEST:
      return { loading: true };
    case PRODUCT_QUERY_SUCCESS:
      return { loading: false, success: true, productsList: action.payload };
    case PRODUCT_QUERY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
