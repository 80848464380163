import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProductById } from "../actions/product";
import { formatCurrency } from "../utils/currency";
import { addToCart } from "../actions/cart";
import { AddShoppingCart } from "@mui/icons-material";
import Alert from "./Alert";
import Loader from "./Loader";

function Product() {
  const dispatch = useDispatch();
  const { product, loading } = useSelector((state) => state.getProductById);
  const [qty, setQty] = useState(1);
  const { id } = useParams();

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(getProductById(id));
  }, [dispatch, id]);

  const addItemToCart = () => {
    dispatch(addToCart(product._id, qty));
    setOpen(true);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="pt-24 mb-16">
          {product && (
            <div className="md:flex justify-around mb-4">
              <div className="md:w-2/4 mt-4 md:mt-8 px-4">
                <span>
                  <img
                    className="h-auto w-full object-contain rounded-lg"
                    src={product.image}
                    alt=""
                  />
                </span>
              </div>
              <div className="md:w-1/4 md:mt-16 px-4 ">
                <h4 className=" text-gray-600 mt-2 text-2xl mb-12 font-bold">
                  {product.name}
                </h4>

                <h4 className="mt-2 font-semibold">Description</h4>
                <p className="py-2">{product.description}</p>

                <h4 className="mt-2 font-semibold">Recommended uses:</h4>
                <ol className="py-2">
                  {product.use &&
                    product.use
                      .split(",")
                      .map((item, index) => <li key={index}>{item}</li>)}
                </ol>

                <h4 className="mt-4 font-semibold">Price</h4>
                <p className="py-2">
                  UGX {product.price && formatCurrency(product.price)}
                </p>

                <div className="mt-2">
                  <span className="mr-2">
                    <span className="font-semibold">Qty</span>{" "}
                    {product.name !== "Pavers"
                      ? "(per piece)"
                      : "(square meter)"}
                  </span>

                  <input
                    className="bg-gray-100 mt-3 border px-4 py-1 rounded-lg focus:outline-none"
                    name="Qty"
                    id=""
                    value={qty}
                    onChange={(e) =>
                      e.target.value > 0 ? setQty(e.target.value) : 1
                    }
                    type="number"
                  />
                </div>

                <div className="mt-10">
                  <span
                    style={{ backgroundColor: "#F59E0B" }}
                    onClick={() => addItemToCart(product.id)}
                    className="text-sm rounded-lg px-4 py-3 text-white font-semibold cursor-pointer"
                  >
                    <span className="mr-4">ADD TO CART</span>{" "}
                    <AddShoppingCart />
                  </span>
                </div>
              </div>
            </div>
          )}
          <Alert
            open={open}
            setOpen={setOpen}
            message="Item added to cart"
            bgColor="success"
          />
        </div>
      )}
    </>
  );
}

export default Product;
