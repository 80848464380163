import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getProfile, updateProfile } from "../actions/user";
import Alert from "./Alert";
import Loader from "./Loader";

function User() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [open, setOpen] = useState(false);
  const [passError, setPassError] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { user, loading } = userDetails;

  const updateProfileDetails = useSelector((state) => state.updateProfile);
  const { success } = updateProfileDetails;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      if (!user || !user.username || !user.email) {
        dispatch(getProfile("profile"));
      } else {
        setUsername(user.username);
        setEmail(user.email);
      }
    }
  }, [userInfo, history, dispatch, user, success]);

  const handleSubmit = () => {
    if (password && password !== confirmPassword) {
      return setPassError(true);
    } else {
      dispatch(updateProfile(username, email, password));
      setPassword("");
      setConfirmPassword("");
      setOpen(true);
    }
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="mt-32 py-4 md:py-1 text-center text-3xl font-bold">
            Account Settings
          </div>
          <div className="flex justify-center py-4 mt-4">
            <div>
              <div className="mb-6">
                <input
                  type="text"
                  value={username}
                  placeholder="Enter username"
                  autoComplete="off"
                  className=" text-center py-1 px-12 rounded-lg border-2 focus:outline-none"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <input
                  type="email"
                  value={email}
                  placeholder="Enter email"
                  autoComplete="off"
                  className=" text-center py-1 px-12 rounded-lg border-2 focus:outline-none"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <input
                  type="password"
                  value={password}
                  placeholder="Enter new password"
                  autoComplete="off"
                  className=" text-center py-1 px-12 rounded-lg border-2 focus:outline-none"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="mb-6">
                <input
                  type="password"
                  value={confirmPassword}
                  placeholder="Confirm new password"
                  autoComplete="off"
                  className=" text-center py-1 px-12 rounded-lg border-2 focus:outline-none"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-2">
            <span
              onClick={() => handleSubmit()}
              className="bg-blue-500 text-white px-20 py-2 rounded-lg cursor-pointer"
            >
              Update
            </span>
          </div>
        </div>
      )}

      <Alert
        open={passError}
        setOpen={setPassError}
        message="Passwords don't match"
        bgColor="error"
      />

      {success && (
        <Alert
          open={open}
          setOpen={setOpen}
          message="Profile updated"
          bgColor="success"
        />
      )}
    </React.Fragment>
  );
}

export default User;
