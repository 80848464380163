export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_QUERY_REQUEST = "PRODUCT_QUERY_REQUEST";
export const PRODUCT_QUERY_SUCCESS = "PRODUCT_QUERY_SUCCESS";
export const PRODUCT_QUERY_FAIL = "PRODUCT_QUERY_FAIL";

export const PRODUCT_ID_REQUEST = "PRODUCT_ID_REQUEST";
export const PRODUCT_ID_SUCCESS = "PRODUCT_ID_SUCCESS";
export const PRODUCT_ID_FAIL = "PRODUCT_ID_FAIL";
