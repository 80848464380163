import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { register } from "../actions/user";
import { Link } from "react-router-dom";
import Alert from "./Alert";
import Loader from "./Loader";

function Signup() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const history = useHistory();

  // const redirect = location.search ? location.search.split("=")[1] : "/";

  const [open, setOpen] = useState(false);
  const [passCheck, setPasswordCheck] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);

  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { loading, error } = useSelector((state) => state.userRegister);

  const handleRegister = () => {
    if (!username || !email || !password || !confirmPassword) {
      return setOpen(true);
    } else if (password && password !== confirmPassword) {
      return setPasswordCheck(true);
    } else {
      dispatch(register(username, email, password));
      setPassword("");
      setConfirmPassword("");
    }
  };

  useEffect(() => {
    if (userInfo) {
      history.push("/");
    } else if (error) {
      setUnauthorized(true);
    } else {
      history.push("/signup");
    }
  }, [userInfo, error, history]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <h2 className="text-center mt-32  uppercase text-3xl mb-8 font-bold">
            Sign up
          </h2>

          <div className="flex justify-center mt-4">
            <div>
              <div className="mt-4">
                <input
                  type="text"
                  value={username}
                  placeholder="username"
                  autoComplete="off"
                  className=" text-center py-1 px-12 rounded-lg border-2 focus:outline-none focus:border-blue-400"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="mt-8">
                <input
                  type="email"
                  value={email}
                  placeholder="e-mail"
                  autoComplete="off"
                  className=" text-center py-1 px-12 rounded-lg border-2 focus:outline-none focus:border-blue-400"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mt-8">
                <input
                  type="password"
                  value={password}
                  placeholder="password"
                  autoComplete="off"
                  className=" text-center py-1 px-12 rounded-lg border-2 focus:outline-none focus:border-blue-400"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="mt-8">
                <input
                  type="password"
                  value={confirmPassword}
                  placeholder="confirm password"
                  autoComplete="password"
                  className=" text-center py-1 px-12 rounded-lg border-2 focus:outline-none focus:border-blue-400"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-8">
            <span
              onClick={() => handleRegister()}
              className="bg-blue-500 text-white px-28 py-2 rounded-lg cursor-pointer"
            >
              Register
            </span>
          </div>

          <div className="flex justify-center mt-8">
            <span className="px-20 py-2 rounded-lg cursor-pointer">
              Already have an account?
              <span className="ml-1 text-blue-500">
                <Link to="/login">Login</Link>
              </span>
            </span>
          </div>
        </div>
      )}

      <Alert
        open={open}
        setOpen={setOpen}
        message="Please fill all the fields"
        bgColor="error"
      />

      <Alert
        open={passCheck}
        setOpen={setPasswordCheck}
        message="Passwords do not match"
        bgColor="error"
      />

      <Alert
        open={unauthorized}
        setOpen={setUnauthorized}
        message={error}
        bgColor="error"
      />
    </>
  );
}

export default Signup;
