export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";

export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";

export const GET_CUSTOMER_ORDERS_REQUEST = "GET_CUSTOMER_ORDERS_REQUEST";
export const GET_CUSTOMER_ORDERS_SUCCESS = "GET_CUSTOMER_ORDERS_SUCCESS";
export const GET_CUSTOMER_ORDERS_FAIL = "GET_CUSTOMER_ORDERS_FAIL";

export const GET_CUSTOMER_ORDERS_RESET = "GET_CUSTOMER_ORDERS_RESET";

export const ORDER_ID_REQUEST = "ORDER_ID_REQUEST";
export const ORDER_ID_SUCCESS = "ORDER_ID_SUCCESS";
export const ORDER_ID_FAIL = "ORDER_ID_FAIL";
