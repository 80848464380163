import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "../utils/currency";
import CartItem from "./CartItem";
import CartIcon from "@mui/icons-material/ShoppingBag";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { getProfile } from "../actions/user";
import { Info } from "@mui/icons-material";

function Cart() {
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);
  const [subTotal, setSubTotal] = useState(0);

  const history = useHistory();

  useEffect(() => {
    getSubTotal(cartItems);
  }, [dispatch, cartItems]);

  dispatch(getProfile("profile"));

  const getSubTotal = (items) => {
    let total = 0;
    items.forEach((item) => {
      total += item.qty * item.price;
    });

    setSubTotal(total);
  };

  const checkoutProcess = () => {
    history.push("/login?redirect=shipping");
  };
  return (
    <>
      <div className="mb-4">
        <h4 className="text-center py-4 md:py-1 text-3xl mt-32 font-bold">
          Shopping Cart
        </h4>

        {cartItems.length < 1 && (
          <div>
            <div className="flex justify-center py-4">
              <span style={{ color: "rgb(234 179 8)" }} className="text-6xl">
                <CartIcon fontSize="inherit" />
              </span>
            </div>
            <h2 className="text-center text-2xl text-gray-400">
              Your Cart is empty
            </h2>
          </div>
        )}
        {cartItems &&
          cartItems.map((item) => <CartItem key={item.id} item={item} />)}
        {cartItems.length > 0 && (
          <div>
            <div className="flex mt-6 justify-center text-gray-500 px-4">
              <Info />
              <span className="ml-4">
                Please ensure to enter the right <strong>quantities</strong> as
                you require before proceeding to shipping.
              </span>
            </div>
            <div className="flex justify-center px-12 border-t pt-12 mt-12">
              <div>
                <h2>
                  <span className="font-bold mr-6">Sub Total: </span>
                  <span className="text-yellow-500">
                    UGX {formatCurrency(subTotal)}
                  </span>
                </h2>
              </div>
            </div>

            <div>
              <h4 className="text-center text-sm text-gray-500">
                <span>Shipping</span>
                <span>
                  <ArrowRight />
                </span>
                <span>Payment</span>
                <span>
                  <ArrowRight />
                </span>
                <span>Place Order</span>
              </h4>
            </div>
            <div
              onClick={() => checkoutProcess()}
              className="mt-6 flex justify-center"
            >
              <span
                style={{ backgroundColor: "#007bff" }}
                className="text-sm rounded-lg px-16 py-2 text-white font-semibold cursor-pointer"
              >
                Proceed To Shipping
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Cart;
