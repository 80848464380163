import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, logout } from "../actions/user";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/styles";
import logo from "../assets/cg.png";
import { getCustomerOrders } from "../actions/order";
import { getProductSearch } from "../actions/product";
import { AccountButton, AccountButtonAlt } from "./AccountBtn";

function Navigation() {
  const [account, showAccount] = useState(false);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { cartItems } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [cartCount, setCartCount] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");
  const history = useHistory();
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    getCartTotal(cartItems);

    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };

    if (offset > 0) {
      showAccount(false);
    }
  }, [cartItems, offset]);

  const getCartTotal = (items) => {
    let count = 0;
    items.forEach((item) => {
      count += Number(item.qty);
    });

    return setCartCount(count);
  };

  const capitalizaFirstLetter = (username) => {
    return username.charAt(0).toUpperCase() + username.slice(1);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleOrders = () => {
    showAccount(false);
    getCustomerOrders(userInfo.userId);
  };

  const handleSettings = () => {
    showAccount(false);
    getProfile("profile");
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (!searchQuery) return;
    dispatch(getProductSearch(searchQuery));
    setSearchQuery("");
    history.push(`/search/${searchQuery}`);
  };

  const StyledBadge = styled(Badge)(() => ({
    "& .MuiBadge-badge": {
      right: -4,
      top: 3,
      padding: "0 4px",
      backgroundColor: "#F59E0B",
    },
  }));

  return (
    <>
      <div
        style={{ backgroundColor: "#0b1826" }}
        className="fixed w-full top-0 left-0 py-4 px-2 md:px-6 text-white flex justify-between"
      >
        <div>
          <span className="text-2xl">
            <Link to="/">
              <img
                className="h-auto w-24 object-contain"
                src={logo}
                alt="logo"
              />
            </Link>
          </span>
        </div>

        {userInfo ? (
          <AccountButton
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleSearch={handleSearch}
            account={account}
            showAccount={showAccount}
            capitalizaFirstLetter={capitalizaFirstLetter}
            userInfo={userInfo}
            handleSettings={handleSettings}
            handleLogout={handleLogout}
            handleOrders={handleOrders}
            cartCount={cartCount}
            StyledBadge={StyledBadge}
          />
        ) : (
          <AccountButtonAlt
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleSearch={handleSearch}
            showAccount={showAccount}
            StyledBadge={StyledBadge}
            cartCount={cartCount}
          />
        )}
      </div>
    </>
  );
}

export default Navigation;
