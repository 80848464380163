import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createOrder } from "../actions/order";
import { formatCurrency } from "../utils/currency";
import Alert from "./Alert";
import { ArrowBackIos as BackIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { CheckCircle as SuccessIcon } from "@mui/icons-material";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import Loader from "./Loader";

function Order() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { cartItems, shippingAddress, paymentMethod } = useSelector(
    (state) => state.cart
  );
  const { error, orderInfo, loading } = useSelector((state) => state.order);

  const [subTotal, setSubTotal] = useState(0);

  const [open, setOpen] = useState(false);

  const [whenSuccess, setWhenSuccess] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }

    if (
      !cartItems ||
      (cartItems.length < 1 && !shippingAddress) ||
      (shippingAddress.length < 1 && !paymentMethod) ||
      paymentMethod.length < 1
    ) {
      history.push("/");
    }

    getSubTotal(cartItems);
  }, [userInfo, history, cartItems, error, paymentMethod, shippingAddress]);

  const getSubTotal = (items) => {
    let total = 0;
    items.forEach((item) => {
      total += item.qty * item.price;
    });

    setSubTotal(total);
  };

  const config = {
    public_key: "FLWPUBK-ec4b71a0241f3bdde5987da54e2c7bf5-X",
    tx_ref: Date.now(),
    amount: `${subTotal}`,
    currency: "UGX",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: `${userInfo?.email}`,
      phonenumber: `${shippingAddress?.phone}`,
      name: `${userInfo?.username}`,
    },
    customizations: {
      title: "CG Shop",
      description: "Payment for items in cart",
      logo: "https://cg.co.ug/assets/cgicon.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const handleOrder = () => {
    if (paymentMethod !== "Card or Mobile Money") {
      dispatch(
        createOrder({
          items: cartItems,
          itemsPrice: subTotal,
          shippingAddress: {
            city: shippingAddress.city,
            address: shippingAddress.address,
            phone: shippingAddress.phone,
          },
          totalPrice: subTotal,
          paymentMethod,
        })
      );

      setWhenSuccess(true);
    } else {
      handleFlutterPayment({
        callback: (response) => {
          dispatch(
            createOrder({
              items: cartItems,
              itemsPrice: subTotal,
              shippingAddress: {
                city: shippingAddress.city,
                address: shippingAddress.address,
                phone: shippingAddress.phone,
              },
              totalPrice: subTotal,
              paymentMethod,
              payment: {
                isPaid: true,
                paidAt: Date.now(),
                flw_ref: response.flw_ref,
              },
              paymentDetails: {
                id: response.transaction_id,
                status: response.status,
                name: response.customer.name,
                email: response.customer.email,
                phone: response.customer.phone,
              },
            })
          );
          closePaymentModal();
          setWhenSuccess(true);
        },
        onClose: () => {
          setOpen(true);
        },
      });
    }
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && !error && whenSuccess && (
        <>
          <div className="text-center text-xl py-4 mt-36 font-bold">
            <span className="mr-2 text-green-600">
              <SuccessIcon fontSize="medium" />
            </span>
            <span className="">Order was Successful</span>
          </div>

          <div className="flex justify-center">
            Thank you for shopping with
            <span className="font-semibold ml-1">CG Shop</span>
          </div>

          <div className="flex justify-center mt-2">
            <span>
              Order ID: #
              <span className="font-bold text-lg">{`${orderInfo?.order?._id}`}</span>
            </span>
          </div>

          <div className="flex justify-center mt-2">
            <div className="mt-6 flex justify-center mb-8">
              <Link to="/customer">
                <span
                  style={{ backgroundColor: "#007bff" }}
                  className="text-sm rounded-lg px-16 py-2 text-white font-semibold cursor-pointer"
                >
                  View Orders
                </span>
              </Link>
            </div>
          </div>
        </>
      )}

      {!loading && !whenSuccess && (
        <>
          <div className="mb-16">
            <div className="pt-36 px-4 md:px-12">
              <Link to="/pay">
                <BackIcon fontSize="large" />
              </Link>
            </div>
            <h4 className="text-center text-3xl mt-4 font-bold">
              Complete Order
            </h4>

            <div className="mt-8">
              <h4 className="text-center font-semibold mb-4">
                Shipping Address
              </h4>
              <div>
                <h6 className="text-center font-semibold text-sm py-2">
                  {userInfo?.username}
                </h6>
                <div className="text-center text-xs">
                  {shippingAddress?.address}, {shippingAddress?.city},{" "}
                  {shippingAddress?.region}
                </div>
                <div className="text-center text-xs py-2">
                  {shippingAddress?.phone}
                </div>
              </div>
            </div>

            <div className="mt-8">
              <h4 className="text-center font-semibold mb-4">Payment Method</h4>

              <div className="text-center text-xs font-semibold">
                {paymentMethod}
              </div>
            </div>

            <div className="mt-8">
              <h4 className="text-center font-semibold mb-4">
                Products Summary
              </h4>

              <div className="flex justify-center mb-3">
                <div>
                  <span className="font-bold">Total Items: </span>
                  {cartItems.reduce((acc, item) => acc + Number(item.qty), 0)}
                </div>
              </div>

              <div className="flex justify-center border-t pt-12 mt-12">
                <div>
                  <span className="font-bold">Sub Total: </span>
                  <span className="text-yellow-500">
                    UGX {formatCurrency(subTotal)}
                  </span>
                </div>
              </div>
            </div>

            <div
              onClick={() => handleOrder()}
              className="flex justify-center mt-2"
            >
              <div className="mt-6 flex justify-center mb-8">
                <span
                  style={{ backgroundColor: "#007bff" }}
                  className="text-sm rounded-lg px-16 py-2 text-white font-semibold cursor-pointer"
                >
                  Confirm Order
                </span>
              </div>
            </div>
          </div>
        </>
      )}

      <Alert
        open={open}
        setOpen={setOpen}
        message="Payment Cancelled"
        bgColor="warning"
      />
    </>
  );
}

export default Order;
