import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { login } from "../actions/user";
import Alert from "./Alert";
import Loader from "./Loader";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

  const { search } = useLocation();

  const redirect = search ? search.split("=")[1] : "/";

  const [open, setOpen] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const handleLogin = () => {
    if (!email || !password) {
      setOpen(true);
    } else {
      dispatch(login(email, password));
    }
  };

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    } else if (error) {
      setUnauthorized(true);
    } else {
      history.push("/login");
    }
  }, [userInfo, history, error, redirect]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <h2 className="text-center mt-36 uppercase font-bold text-3xl mb-12">
            Login
          </h2>

          <div className="flex justify-center mt-4">
            <div autoComplete="off">
              <div className="mb-6">
                <input
                  type="email"
                  autoComplete="off"
                  value={email}
                  placeholder="e-mail"
                  className="text-center py-1 px-12 rounded-lg border-2 focus:outline-none focus:border-blue-400"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mt-8">
                <input
                  type="password"
                  autoComplete="off"
                  value={password}
                  placeholder="password"
                  className="text-center py-1 px-12 rounded-lg border-2 focus:outline-none focus:border-blue-400"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-8">
            <span
              onClick={() => handleLogin()}
              className="bg-blue-500 text-white px-28 py-2 rounded-lg cursor-pointer"
            >
              Login
            </span>
          </div>
          <div className="flex justify-center mt-4">
            <span className="px-8 md:px-20 py-2 rounded-lg cursor-pointer text-base">
              Don't have an account?
              <span className="ml-1 md:ml-2 text-blue-500">
                <Link to="/signup">Sign up</Link>
              </span>
            </span>
          </div>
        </div>
      )}
      <Alert
        open={open}
        setOpen={setOpen}
        message="Please enter email and password"
        bgColor="error"
      />
      <Alert
        open={unauthorized}
        setOpen={setUnauthorized}
        message="Invalid email or password"
        bgColor="error"
      />
    </>
  );
}

export default Login;
