import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { addShippingAddress } from "../actions/cart";
import { ArrowRight } from "@mui/icons-material";
import Alert from "./Alert";
import { ArrowBackIos as BackIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";

function Shipping() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { shippingAddress, cartItems } = useSelector((state) => state.cart);

  const [phone, setPhone] = useState(shippingAddress.phone);
  const [city, setCity] = useState(shippingAddress.city);
  const [region, setRegion] = useState(shippingAddress.region);
  const [address, setAddress] = useState(shippingAddress.address);

  const [open, setOpen] = useState(false);

  const history = useHistory();

  const handleShipping = () => {
    if (!phone || !city || !region || !address) {
      return setOpen(true);
    }
    dispatch(addShippingAddress({ city, region, address, phone }));
    history.push("/pay");
  };

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }

    if (!cartItems || cartItems.length < 1) {
      history.push("/");
    }
  }, [userInfo, history, cartItems]);

  return (
    <>
      <div className="mb-16">
        <div className="pt-36 px-4 md:px-12">
          <Link to="/cart">
            <BackIcon fontSize="large" />
          </Link>
        </div>

        <h4 className="text-center text-3xl mt-4 font-bold">
          Shipping Information
        </h4>

        <div className="flex justify-center mt-8 mb-12">
          <div>
            <div className="mb-6">
              <input
                type="tel"
                value={phone}
                placeholder="Phone Number"
                autoComplete="off"
                className=" text-center py-1 px-12 rounded-lg border-2 focus:outline-none"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <input
                type="text"
                value={city}
                placeholder="City"
                autoComplete="off"
                className=" text-center py-1 px-12 rounded-lg border-2 focus:outline-none"
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="mt-8">
              <input
                type="text"
                value={region}
                placeholder="Region"
                autoComplete="off"
                className="text-center py-1 px-12 rounded-lg border-2 focus:outline-none"
                onChange={(e) => setRegion(e.target.value)}
              />
            </div>

            <div className="mt-8 m">
              <input
                type="text"
                value={address}
                placeholder="Address / Area / Town"
                autoComplete="off"
                className="text-center py-1 px-12 rounded-lg border-2 focus:outline-none"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="mt-8 border-t pt-12">
          <h4 className="text-center text-sm text-gray-500">
            <span>Shipping</span>
            <span>
              <ArrowRight />
            </span>
            <span>Payment</span>
            <span>
              <ArrowRight />
            </span>
            <span>Place Order</span>
          </h4>
        </div>

        <div className="flex justify-center mt-2">
          <div
            className="mt-6 flex justify-center mb-8"
            onClick={() => handleShipping()}
          >
            <span
              style={{ backgroundColor: "#007bff" }}
              className="text-sm rounded-lg px-16 py-2 text-white font-semibold cursor-pointer"
            >
              Proceed to Payment
            </span>
          </div>
        </div>

        <Alert
          open={open}
          setOpen={setOpen}
          message="Please fill all the fields"
          bgColor="error"
        />
      </div>
    </>
  );
}

export default Shipping;
