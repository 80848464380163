import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_ID_REQUEST,
  PRODUCT_ID_SUCCESS,
  PRODUCT_ID_FAIL,
  PRODUCT_QUERY_FAIL,
  PRODUCT_QUERY_REQUEST,
  PRODUCT_QUERY_SUCCESS,
} from "../constants/product";
import axios from "axios";

export const getProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_LIST_REQUEST,
    });

    const { data } = await axios.get(
      "https://cgug.herokuapp.com/products/list"
    );
    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getProductById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_ID_REQUEST,
    });

    const { data } = await axios.get(
      `https://cgug.herokuapp.com/products/${id}`
    );
    dispatch({
      type: PRODUCT_ID_SUCCESS,
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getProductSearch = (query) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_QUERY_REQUEST,
    });

    const { data } = await axios.get(
      `https://cgug.herokuapp.com/products/search?q=${query}`
    );
    dispatch({
      type: PRODUCT_QUERY_SUCCESS,
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_QUERY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
