import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  updateProfileReducer,
  resetPasswordReducer,
  updatePasswordReducer,
} from "./reducers/user";

import {
  getProductByIdReducer,
  getProductsReducer,
  getProductSearchReducer,
} from "./reducers/product";

import {
  orderReducer,
  getCustomerOrdersReducer,
  getOrderByIdReducer,
} from "./reducers/order";

import { cartReducer } from "./reducers/cart";

const userInfoStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const cartItemsStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const shippingInfoStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : [];

const paymentMethodStorage = localStorage.getItem("paymentMethod")
  ? JSON.parse(localStorage.getItem("paymentMethod"))
  : [];

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  updateProfile: updateProfileReducer,
  getProducts: getProductsReducer,
  getProductById: getProductByIdReducer,
  cart: cartReducer,
  order: orderReducer,
  orders: getCustomerOrdersReducer,
  orderById: getOrderByIdReducer,
  reset: resetPasswordReducer,
  password: updatePasswordReducer,
  search: getProductSearchReducer,
});

const initialState = {
  userLogin: { userInfo: userInfoStorage },
  cart: {
    cartItems: cartItemsStorage,
    shippingAddress: shippingInfoStorage,
    paymentMethod: paymentMethodStorage,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
