import React from "react";
import ProductList from "./ProductList";

function Home() {
  return (
    <div>
      <ProductList />
    </div>
  );
}

export default Home;
