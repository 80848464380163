import React from "react";
import { formatCurrency } from "../utils/currency";
import RemoveIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { addToCart, removeFromCart } from "../actions/cart";

function CartItem({ item }) {
  const dispatch = useDispatch();

  const removeItem = (id) => {
    dispatch(removeFromCart(id));
  };
  return (
    <>
      <div className="md:flex justify-around mb-4 mt-12">
        <div className="md:flex justify-between">
          <div>
            <span>
              <img
                className="h-60 w-full md:w-52 object-contain px-4"
                src={item.image}
                alt=""
              />
            </span>
          </div>

          <div className="px-4 mt-16">
            <div className="md:flex justify-between">
              <div className="mr-4">
                <h2 className="mt-4 font-semibold text-lg">{item.name}</h2>
                <div
                  onClick={() => removeItem(item.id)}
                  className="py-2 cursor-pointer"
                >
                  <span className="text-yellow-500 mr-2">
                    <RemoveIcon fontSize="small" />
                  </span>
                  <span className="text-yellow-500">Remove</span>
                </div>
              </div>
              <div className="flex ">
                <div className="mr-4 md:mr-12">
                  <h2 className="mt-4 font-semibold">Price</h2>
                  <p className="py-2">
                    UGX {item && formatCurrency(item.price)}
                  </p>
                </div>
              </div>

              <div>
                <span>
                  <h2 className="mt-4 font-semibold">
                    <span className="mr-2">
                      Qty{" "}
                      {item.name !== "Pavers"
                        ? "(per piece)"
                        : "(square meter)"}
                    </span>
                  </h2>

                  <input
                    className="bg-gray-100 mt-3 border px-4 py-1 rounded-lg focus:outline-none"
                    name="Qty"
                    id=""
                    value={item.qty}
                    onChange={(e) =>
                      dispatch(
                        addToCart(
                          item.id,
                          Number(e.target.value > 0 ? e.target.value : 1)
                        )
                      )
                    }
                    type="number"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CartItem;
