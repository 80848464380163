import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { addPaymentMethod } from "../actions/cart";
import { ArrowRight } from "@mui/icons-material";
import Alert from "./Alert";
import { ArrowBackIos as BackIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";

function Payment() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const {
    paymentMethod: payMethod,
    cartItems,
    shippingAddress,
  } = useSelector((state) => state.cart);

  const [paymentMethod, setPaymentMethod] = useState(payMethod);

  const [open, setOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }

    if (
      !cartItems ||
      (cartItems.length < 1 && !shippingAddress) ||
      shippingAddress.length < 1
    ) {
      history.push("/");
    }
  }, [userInfo, history, cartItems, shippingAddress, paymentMethod]);

  const handlePayment = () => {
    if (!paymentMethod || paymentMethod.length < 1) {
      return setOpen(true);
    }
    dispatch(addPaymentMethod(paymentMethod));
    history.push(`/order`);
  };

  return (
    <>
      <div className="mb-16">
        <div className="pt-36 px-4 md:px-12">
          <Link to="/shipping">
            <BackIcon fontSize="large" />
          </Link>
        </div>

        <h4 className="text-center text-3xl mt-4 font-bold">Payment Method</h4>

        <div className="flex justify-center mt-8">
          <div>
            <div className="mb-6">
              <div>
                <div className="flex items-center mr-4 mb-4">
                  <input
                    id="radio1"
                    type="radio"
                    name="radio"
                    checked={
                      "Card or Mobile Money" === paymentMethod ? true : false
                    }
                    className="hidden"
                    value="Card or Mobile Money"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />
                  <label
                    htmlFor="radio1"
                    className="flex items-center cursor-pointer text-xl"
                  >
                    <span className="w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                    Card or Mobile Money
                  </label>
                </div>
                <div>
                  <p className="text-xs">
                    Pay with your card or mobile money account
                  </p>
                </div>

                <div className="flex items-center mr-4 mt-8 mb-4">
                  <span>
                    <input
                      id="radio2"
                      type="radio"
                      name="radio"
                      checked={
                        "Cash On Delivery" === paymentMethod ? true : false
                      }
                      value="Cash On Delivery"
                      className="hidden"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                    <label
                      htmlFor="radio2"
                      className="flex items-center cursor-pointer text-xl"
                    >
                      <span className="w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                      Cash on Delivery
                    </label>
                  </span>
                </div>
                <div>
                  <p className="text-xs text-center">
                    Pay at the time of delivery
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 border-t pt-12">
          <h4 className="text-center text-sm text-gray-500">
            <span>Shipping</span>
            <span>
              <ArrowRight />
            </span>
            <span>Payment</span>
            <span>
              <ArrowRight />
            </span>
            <span>Place Order</span>
          </h4>
        </div>

        <div className="flex justify-center mt-2">
          <div
            className="mt-6 flex justify-center mb-8"
            onClick={() => handlePayment()}
          >
            <span
              style={{ backgroundColor: "#007bff" }}
              className="text-sm rounded-lg px-16 py-2 text-white font-semibold cursor-pointer"
            >
              Place Order
            </span>
          </div>
        </div>

        <Alert
          open={open}
          setOpen={setOpen}
          message="Please select payment method"
          bgColor="error"
        />
      </div>
    </>
  );
}

export default Payment;
